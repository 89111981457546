<template>

    <div class="form-compact form-compact-channel-subscription">
      <div class="form-compact-header">
        <v-system-bar dark color="primary">
          <v-icon class="mr-1">{{ $t('CHANNEL_SUBSCRIPTION.ICON') }}</v-icon>
          <template v-if="id">
            <small><strong class="text-uppercase">{{ $t('CHANNEL_SUBSCRIPTION.FORM_TITLE_UPDATE') }}</strong></small>
            <v-icon class="ml-4" size="12">mdi-pound</v-icon>
            <small>{{ loaded_values.id }}</small>
          </template>
          <small v-else><strong class="text-uppercase">{{ $t('CHANNEL_SUBSCRIPTION.FORM_TITLE_CREATE') }}</strong></small>
          <v-spacer/>
          <v-icon
            small
            class="clickable"
            @click="$emit('close')"
          >
            mdi-close
          </v-icon>
        </v-system-bar>

        <v-toolbar
          color="transparent"
          flat
          height="66px"
        >
          <v-toolbar-title class="primary--text">
            <v-img
              :src="getImage(loaded_values)"
              height="25px"
              max-width="150px"
              contain
            />
          </v-toolbar-title>
          <v-spacer />
          <div class="mr-6" >
            <status-label :item="loaded_values"/>
          </div>
          <div class="mr-6 text-nowrap" v-if="ids.length > 1">
            <v-tooltip bottom transition="none">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  dark
                  fab
                  x-small
                  depressed
                  color="grey"
                  @click="prevItem"
                  class="mr-2"
                >
                  <v-icon color="white">mdi-arrow-left</v-icon>
                </v-btn>
              </template>
              {{ $t('COMMON.PREVIOUS') }}
            </v-tooltip>
            <v-tooltip bottom transition="none">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  dark
                  fab
                  x-small
                  depressed
                  color="grey"
                  @click="nextItem"
                >
                  <v-icon color="white">mdi-arrow-right</v-icon>
                </v-btn>
              </template>
              {{ $t('COMMON.NEXT') }}
            </v-tooltip>
          </div>
          <div class="mr-6 d-flex text-nowrap" v-if="id">
            <div>
              <v-tooltip bottom transition="none">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    dark
                    fab
                    x-small
                    depressed
                    color="error"
                    class="mr-2"
                    @click="removeItem"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                {{ $t('COMMON.REMOVE') }}
              </v-tooltip>
              <v-tooltip bottom transition="none">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    dark
                    fab
                    x-small
                    depressed
                    color="warning"
                    class="mr-2"
                    @click="resetItem"
                  >
                    <v-icon>mdi-cancel</v-icon>
                  </v-btn>
                </template>
                {{ $t('COMMON.RESET') }}
              </v-tooltip>
              <v-tooltip bottom transition="none">
                <template v-slot:activator="{ on, attrs }">
                  <a :href="getStoragePath(loaded_values)" target="_blank">
                    <v-btn
                      x-small
                      fab
                      depressed
                      dark
                      color="indigo"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-file-download-outline</v-icon>
                    </v-btn>
                  </a>
                </template>
                {{ $t('CHANNEL_SUBSCRIPTION.DOWNLOAD_FILE') }}
              </v-tooltip>
            </div>
          </div>
          <v-tooltip bottom transition="none">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                fab
                small
                depressed
                color="success"
                @click="saveItem"
                :disabled="!valid"
              >
                <v-icon>mdi-check-bold</v-icon>
              </v-btn>
            </template>
            {{ $t('COMMON.SAVE') }}
          </v-tooltip>
        </v-toolbar>
        <div class="toolbar-separator primary ml-4 mr-4"/>

      </div>

      <div :class="['form-compact-content', blocked ? 'blocked' : '']">

        <v-overlay :value="blocked" v-if="loaded_values" color="white" :opacity="0.75" absolute>
          <div class="text-center">
            <v-icon :color="loaded_values.status_type" size="42">
              {{ $t(loaded_values.status_code + '_ICON') }}
            </v-icon>
            <p class="black--text">{{ $t(loaded_values.status_code) }}</p>
            <div class="mt-4">
              <v-btn
                depressed
                small
                color="grey"
                @click="loadItem"
              >
                {{ $t('COMMON.RELOAD') }}
              </v-btn>
            </div>
          </div>
        </v-overlay>

        <Loading :value="!!loading" />
        <v-container fluid class="pt-4 pb-10">
          <v-form v-model="valid">
            <!-- <div class="pr-6 pl-6">
              <FormChannelSubscriptionCompactContracts @input="handleInput" :values="values" :loaded_values="loaded_values" />
            </div> -->
            <div class="pr-6 pl-6">
              <FormChannelSubscriptionCompactIntro @input="handleInput" :values="values" />
            </div>
          </v-form>
        </v-container>
      </div>

    </div>

</template>


<script>

import Loading from '@/components/Loading'
import StatusLabel from '@/components/StatusLabel'

import FormChannelSubscriptionMixins from '../FormChannelSubscriptionMixins'
import FormChannelSubscriptionCompactIntro from './FormChannelSubscriptionCompact.intro'
// import FormChannelSubscriptionCompactContracts from './FormChannelSubscriptionCompact.contracts'

export default {
  name:"FormChannelSubscriptionCompact",
  mixins: [FormChannelSubscriptionMixins],
  components: {
    Loading,
    StatusLabel,
    FormChannelSubscriptionCompactIntro,
    // FormChannelSubscriptionCompactContracts
  },
  data: () => ({
    valid: false,
    interval: null,
  }),
  computed: {
    blocked () {
      return this.loaded_values ? ['info', 'warning'].indexOf(this.loaded_values.status_type) !== -1 : false
    }
  },
  watch: {
    blocked (n) {
      if (!n) this.stopInterval()
      else this.startInterval()
    }
  },
  beforeDestroy () {
    this.stopInterval()
  },
  methods: {

    getStoragePath (item) {
      if (!item.channel_params) item.channel_params = { test_mode: true }
      return `${process.env.VUE_APP_FILESHARE_PUBLIC}/companies/${
        item.company_id  }/`
        + `channels${  item.channel_params.test_mode ? '-test' : ''  }/${
          item.channel.controller_name.toLowerCase()  }/${
          item.channel_params.group_by === 'company' ? `company-${  item.company_id}` : `office-${  item.office_id}`
        }.xml`
    },

    getImage(item, size = 'original') {
      if (item) {
        const url =
        `${process.env.VUE_APP_ASSETS}/channels/`
        return `${url + item.channel.controller_name.toLowerCase()}.png`
      }
    },

    startInterval () {
      clearInterval(this.interval)
      this.interval = setInterval(() => {
        this.loadItem()
      }, 5000)
    },
    stopInterval () {
      clearInterval(this.interval)
      this.$emit('update')
    }
  }
}
</script>
